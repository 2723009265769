<template>
    <div class="sld_chat_page">

        <chatLeftList @curChatMemInfo="curChatMemInfo" @switchMember="switchMember" ref="left" :class="{'hide': isCollapseLeft}"
            :connectBaseData="connectBaseData" />

        <div class="chat_zone" :style="{ 'background': curChatMemberId && curChatMemberId == socketInfo.data.memberId ? '#fff' : '#f1f1f2' }" :class="{'shrink': !isCollapseRight}">
            <section class="chat_zone_container" v-show="curChatMemberId && curChatMemberId == socketInfo.data.memberId">
                <div class="top">
                    <div class="top_title">
                        <div class="top_title_container" @click="handleToggleLeft">
                            <img class="icon" src="@/assets/member.png" />
                            <span>{{ curChatMemberInfo.data.memberName }}<span class="source-url" v-if="chatSourceUrl">（{{ chatSourceUrl }}）</span></span>
                        </div>
                        <a @click="handleOpenSetting" class="btn-remark" href="javascript:void(0)">
                            <img class="icon" src="@/assets/remark.png" />
                            <span class="text">{{ curChatMemberInfo.data.memberRemarks || '' }}</span>
                        </a>
                    </div>
                    <div class="action_box">
                        <a class="btn-top" href="javascript:void(0);" @click="handleToggleTop">
                            <img class="icon" src="@/assets/top.png">
                        </a>
                        <el-popconfirm :title="L['确定结束与该会员的聊天吗？']" @confirm="handleDeleteMember"
                            :confirmButtonText="L['确定']" :cancelButtonText="L['取消']">
                            <template #reference>
                                <a class="btn-delete" href="javascript:void(0);">
                                    <img class="icon" src="@/assets/service/delete.png">
                                </a>
                            </template>
                        </el-popconfirm>
                        <a @click="handleToggleRight" class="toggle-menu" :class="{'shrink': isCollapseRight}" href="javascript:void(0);">
                            <img class="icon" src="@/assets/menu.png">
                        </a>
                    </div>
                </div>
                <div class="chat_wrap">
                    <!-- 聊天界面框start -->
                    <div class="chat_window">
                        <el-scrollbar ref="scroll" id="chat">
                            <!-- <div v-if="msgList.list.length>0"> -->
                            <div v-for="(item, index) in msgList.list" :key="index">
                                <div v-if="index == 0 || (index > 0 && $isShowTime(msgList.list[index - 1].createTime, item.createTime))"
                                    class="time_line">
                                    <span class="time_line_msg">{{ $formatChatTime(item.createTime) }}</span>
                                </div>
                                <template v-if="[1, 2].includes(item.msgState)">
                                        <!-- 会员发送的信息start -->
                                        <template v-if="item.userType == 1">

                                            <!-- 文本类型start -->
                                            <div class="chat_ladder user" v-if="item.msgType == 1">
                                                <img :src="item.memberAvatar" class="conten_left">
                                                <div class="content_right">
                                                    <p class="name"><span>{{ item.memberName }}</span><span class="divide">—></span><span>{{ item.adminName }}</span></p>
                                                    <span class="content_text text_type"
                                                        v-html="JSON.parse(item.msgContent).content"></span>
                                                </div>
                                            </div>
                                            <!-- 文本类型end -->

                                            <!-- 图片类型start -->
                                            <div class="chat_ladder user" v-if="item.msgType == 2">
                                                <img :src="item.memberAvatar" class="conten_left">
                                                <div class="content_right">
                                                    <p class="name"><span>{{ item.memberName }}</span><span class="divide">—></span><span>{{ item.adminName }}</span></p>
                                                    <div class="content_text image_type">
                                                        <!-- <el-image :src="JSON.parse(item.msgContent).pic"
                                                            :preview-src-list="[JSON.parse(item.msgContent).pic]"
                                                            hide-on-click-modal="true">
                                                            <template #placeholder>
                                                                <div class="image-slot">
                                                                    <i class="el-icon-picture-outline"></i>
                                                                </div>
                                                            </template>
                                                        </el-image> -->
                                                        <img class="msg-image" :src="JSON.parse(item.msgContent).pic" @click="hanldeViewImage" />
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- 图片类型end -->

                                            <!-- 商品类型start -->
                                            <div class="chat_ladder user" v-if="item.msgType == 3">
                                                <img :src="item.memberAvatar" class="conten_left">
                                                <div class="content_right">
                                                    <p class="name"><span>{{ item.memberName }}</span><span class="divide">—></span><span>{{ item.adminName }}</span></p>
                                                    <div class="content_text goods_type">
                                                        <div class="goods_model"
                                                            @click="toDetail(JSON.parse(item.msgContent), 'goods')">
                                                            <img :src="JSON.parse(item.msgContent).goodsImage" alt="">
                                                            <div class="goods_info">
                                                                <div class="goods_info_title">
                                                                    {{ JSON.parse(item.msgContent).goodsName }}
                                                                </div>
                                                                <div class="goods_info_bottom">
                                                                    <span>￥{{ JSON.parse(item.msgContent).goodsPrice }}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- 商品类型end -->

                                            <!-- 订单类型start -->
                                            <div class="chat_ladder user" v-if="item.msgType == 4">
                                                <img :src="item.memberAvatar" class="conten_left">
                                                <div class="content_right">
                                                    <p class="name"><span>{{ item.memberName }}</span><span class="divide">—></span><span>{{ item.adminName }}</span></p>
                                                    <div class="content_text order_type"
                                                        @click="toDetail(JSON.parse(item.msgContent), 'order')">
                                                        <div class="order_title">
                                                            <span class="order_sn">{{ L['订单号'] }}：<i>{{
                                                                JSON.parse(item.msgContent).orderSn }}</i></span>
                                                            <span class="order_time">{{ JSON.parse(item.msgContent).createTime
                                                            }}</span>
                                                        </div>
                                                        <div class="goods_model order_type">
                                                            <img :src="JSON.parse(item.msgContent).goodsImage" alt="">
                                                            <div class="goods_info">
                                                                <div class="goods_info_title">
                                                                    {{ JSON.parse(item.msgContent).goodsName }}
                                                                </div>
                                                                <div class="goods_info_bottom goods_info_bottom_detial">
                                                                    <span>￥{{ JSON.parse(item.msgContent).moneyAmount ?
                                                                        JSON.parse(item.msgContent).moneyAmount :
                                                                        JSON.parse(item.msgContent).goodsPrice }}</span>
                                                                    <span class="goods_info_bottom_ok">{{
                                                                        JSON.parse(item.msgContent).orderStateValue }}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- 订单类型end -->

                                            <!-- 视频类型start -->
                                            <div class="chat_ladder user" v-if="item.msgType == 7">
                                                <img :src="item.memberAvatar" class="conten_left">
                                                <div class="content_right" @contextmenu="($event) => onContextmenu($event, item)">
                                                    <p class="name"><span>{{ item.memberName }}</span><span class="divide">—></span><span>{{ item.adminName }}</span></p>
                                                    <div class="content_text image_type">
                                                        <video width="300" :src="JSON.parse(item.msgContent).video" controls preload="metadata"></video>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- 视频类型end -->
                                        </template>
                                        <!-- 会员发送的信息end -->


                                        <!-- 客服发送的消息start -->
                                        <template v-if="[2, 3].includes(item.userType)">
                                            
                                            <!-- 文本类型start -->
                                            <div class="chat_ladder merchant" v-if="item.msgType == 1">
                                                <div :class="{ msg_read: item.msgState == 1, msg_not_read: item.msgState == 2 }">
                                                    {{ item.msgState == 1 ? L['已读'] : L['未读'] }}</div>
                                                <div class="content_right" @contextmenu="($event) => onContextmenu($event, item)" @touchend="($event) => onContextmenu($event, item)">
                                                    <p class="name">{{ item.adminName }}</p>
                                                    <span class="content_text text_type"
                                                        v-html="JSON.parse(item.msgContent).content"></span>
                                                </div>
                                                <img :src="item.adminAvatar" alt="" class="user_avatar">
                                            </div>
                                            <!-- 文本类型end -->

                                            <!-- 商品类型start -->
                                            <div class="chat_ladder merchant" v-if="item.msgType == 3">
                                                <div :class="{ msg_read: item.msgState == 1, msg_not_read: item.msgState == 2 }">
                                                    {{ item.msgState == 1 ? L['已读'] : L['未读'] }}</div>
                                                <div class="content_right" @contextmenu="($event) => onContextmenu($event, item)">
                                                    <p class="name">{{ item.adminName }}</p>
                                                    <div class="content_text goods_type"
                                                        @click="toDetail(JSON.parse(item.msgContent), 'goods')">
                                                        <div class="goods_model" :key="index">
                                                            <img :src="JSON.parse(item.msgContent).goodsImage" alt="">
                                                            <div class="goods_info">
                                                                <div class="goods_info_title">
                                                                    {{ JSON.parse(item.msgContent).goodsName }}
                                                                </div>
                                                                <div class="goods_info_bottom">
                                                                    <span>￥{{ JSON.parse(item.msgContent).goodsPrice }}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <img :src="item.adminAvatar" alt="" class="user_avatar">
                                            </div>
                                            <!-- 商品类型end -->


                                            <!-- 订单类型start -->
                                            <div class="chat_ladder merchant" v-if="item.msgType == 4">
                                                <div :class="{ msg_read: item.msgState == 1, msg_not_read: item.msgState == 2 }">
                                                    {{ item.msgState == 1 ? L['已读'] : L['未读'] }}</div>
                                                <div class="content_right" @contextmenu="($event) => onContextmenu($event, item)">
                                                    <p class="name">{{ item.adminName }}</p>
                                                    <div class="content_text order_type"
                                                        @click="toDetail(JSON.parse(item.msgContent), 'order')">
                                                        <div class="order_title">
                                                            <span class="order_sn">{{ L['订单号'] }}：<i>{{
                                                                JSON.parse(item.msgContent).orderSn }}</i></span>
                                                            <span class="order_time">{{ JSON.parse(item.msgContent).createTime
                                                            }}</span>
                                                        </div>
                                                        <div class="goods_model order_type">
                                                            <img :src="JSON.parse(item.msgContent).goodsImage" alt="">
                                                            <div class="goods_info">
                                                                <div class="goods_info_title">
                                                                    {{ JSON.parse(item.msgContent).goodsName }}
                                                                </div>
                                                                <div class="goods_info_bottom goods_info_bottom_detial">
                                                                    <span>￥{{ JSON.parse(item.msgContent).goodsPrice }}</span>
                                                                    <span class="goods_info_bottom_ok">{{
                                                                        JSON.parse(item.msgContent).orderStateValue }}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <img :src="item.adminAvatar" alt="" class="user_avatar">
                                            </div>
                                            <!-- 订单类型end -->

                                            <!-- 图片类型start -->
                                            <div class="chat_ladder merchant" v-if="item.msgType == 2">
                                                <div :class="{ msg_read: item.msgState == 1, msg_not_read: item.msgState == 2 }">
                                                    {{ item.msgState == 1 ? L['已读'] : L['未读'] }}</div>
                                                <div class="content_right" @contextmenu="($event) => onContextmenu($event, item)">
                                                    <p class="name">{{ item.adminName }}</p>
                                                    <div class="content_text image_type">
                                                        <!-- <el-image :src="JSON.parse(item.msgContent).pic"
                                                            :preview-src-list="[JSON.parse(item.msgContent).pic]"
                                                            hide-on-click-modal="true">
                                                            <template #placeholder>
                                                                <div class="image-slot">
                                                                    <i class="el-icon-picture-outline"></i>
                                                                </div>
                                                            </template>
                                                        </el-image> -->
                                                        <img class="msg-image" :src="JSON.parse(item.msgContent).pic" @click="hanldeViewImage" />
                                                    </div>
                                                </div>
                                                <img :src="item.adminAvatar" alt="" class="user_avatar">
                                            </div>
                                            <!-- 图片类型end -->

                                            <!-- 视频类型start -->
                                            <div class="chat_ladder merchant" v-if="item.msgType == 7">
                                                <div :class="{ msg_read: item.msgState == 1, msg_not_read: item.msgState == 2 }">
                                                    {{ item.msgState == 1 ? L['已读'] : L['未读'] }}</div>
                                                <div class="content_right" @contextmenu="($event) => onContextmenu($event, item)">
                                                    <p class="name">{{ item.adminName }}</p>
                                                    <div class="content_text image_type">
                                                        <video width="300" :src="JSON.parse(item.msgContent).video" controls preload="metadata"></video>
                                                    </div>
                                                </div>
                                                <img :src="item.adminAvatar" alt="" class="user_avatar">
                                            </div>
                                            <!-- 视频类型end -->

                                            <!-- 转接类型start -->
                                            <div class="time_line" v-if="item.msgType == 6">
                                                <span class="time_line_msg" v-html="JSON.parse(item.msgContent).content"></span>
                                            </div>
                                            <!-- 转接类型start -->

                                        </template>
                                        <!-- 客服发送的消息end -->
                                        
                                </template>
                                <template v-else>
                                    <div class="time_line">
                                        <span class="time_line_msg">你撤回了一条消息<a class="btn-reedit" href="javascript:void(0)" v-if="item.msgType === 1" @click="handleReedit(item)" @touchend="handleReedit(item)">重新编辑</a></span>
                                    </div>
                                </template>
                            </div>
                            <!-- </div> -->
                            <!-- 这里加个div标签是防止一开始出现‘can not read property 0 of null’的错误 -->
                            <div></div>
                        </el-scrollbar>
                    </div>
                    <!-- 聊天界面框end -->

                    <!-- 聊天输入框start -->
                    <div class="chat_input" id="chat_input">
                        <div class="chat_tool">
                            <!-- 表情start -->
                            <el-popover placement="top-start" :width="300" trigger="click">
                                <template #reference>
                                    <a class="face_ex">
                                        <img src="@/assets/service/face_ex.png" alt="" title="表情">
                                        <!-- <i class="iconfont icon-biaoqing"></i> -->
                                    </a>
                                </template>
                                <div class="emoji_s">
                                    <div v-for="(e, i) in emojis" :key="i" :title="e.title" class="emoji_item"
                                        @click="transEmoji(e.src)">
                                        <img :src="'/static/local/emoji/' + e.src" alt="">
                                    </div>
                                </div>
                            </el-popover>
                            <!-- 表情end -->

                            <!-- 图片start -->
                            <label for="image">
                                <img src="@/assets/service/pic.png" alt="" title="发送图片">
                            </label>
                            <input type="file" id="image" @change="getImage" ref="imageFile" accept=".png, .jpg, .jpeg, .gif">
                            <!-- 图片end -->

                            <!-- 视频start -->
                            <label for="video">
                                <img class="btn-video" src="@/assets/service/video.png" alt="" title="发送视频">
                            </label>
                            <input type="file" id="video" @change="getVideo" ref="videoFile" accept=".mp4">
                            <!-- 视频end -->

                            <!-- 快捷语恢复start -->
                            <el-popover placement="top-start" popper-class="custom-popover-box" :width="780" trigger="click" v-model:visible="popFlag">
                                <template #reference>
                                    <img src="@/assets/service/quick_reply.png" alt="" title="快捷回复" @click="getQuickReplyActive">
                                </template>
                                <div class="pop_header">
                                    <p>{{ L['常用语快捷回复'] }}</p>
                                    <p><i class="el-icon-close" @click="popFlag = false"></i></p>
                                </div>
                                <div class="pop_list"
                                    v-if="quickReplyList && quickReplyList.list && quickReplyList.list.length > 0">
                                    <div class="pop_item" v-for="(item, index) in quickReplyList.list" :key="index"
                                        @click="sendQuick(item.msgContent)">
                                        {{ item.msgContent }}
                                    </div>
                                </div>
                                <div class="empty_quick" v-if="!quickReplyList.list.length > 0">
                                    <img src="@/assets/goods/empty_data.png" alt="">
                                    <p>{{ L['暂未设置快捷回复~'] }}</p>
                                </div>
                            </el-popover>
                            <!-- 快捷语end -->

                            <!-- 转接start -->
                            <img src="@/assets/service/tran_touch.png" alt="" title="转接客服" @click="chatTransfer">
                            <!-- 转接end -->

                            <!-- 聊天记录start -->
                            <img class="btn-chatmsg" src="@/assets/service/chat_msg.png" alt="" title="聊天记录" @click="handleOpenChatMsg">
                            <!-- 聊天记录end -->

                            <a @click="scrolltoNewMsg" class="btn-scroll" href="javascript:void(0)" title="回到底部">
                                <img class="icon" src="@/assets/down.png" />
                            </a>
                        </div>
                        <div class="chat_input_area">
                            <!-- <el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="textarea" resize="none"
                                id="el-textarea" @keyup.enter="send()">
                            </el-input> -->
                            <pre name="sendBox" contenteditable="true" class="send-textarea" @input="preInput" ref="sendBox"
                                @paste="listenPaste" @keyup.enter="send()"></pre>
                        </div>
                        <div class="input_button">
                            <button @click="send()">{{ L['发送'] }}<i class="iconfont"></i></button>
                        </div>
                    </div>
                    <!-- 聊天输入框end -->
                </div>

                <!-- 客服转接框start -->
                <el-dialog title="客服列表" v-model="dialogVisible" custom-class="transfer-dialog-modal" top="10vh">
                    <div class="transfer-box">
                        <div class="transfer-column">
                            <el-scrollbar>
                                <div class="transfer_list">
                                    <div class="transfer_item" v-for="(venItem, venIndex) in vendorList.plist" :key="venIndex">
                                        <img :src="venItem.adminAvatar" alt="">
                                        <div class="text_con">
                                            <p class="service_name">{{ venItem.adminName }}</p>
                                            <p class="service_state color-blue" v-if="venItem.workState === 1">{{ L['在线'] }}</p>
                                            <p class="service_state color-red" v-if="venItem.workState === 2">{{ L['忙碌'] }}</p>
                                            <p class="service_state color-yellow" v-if="venItem.workState === 3">{{ L['休息'] }}</p>
                                        </div>
                                        <div class="type" v-if="venItem.isSupper === 0">
                                            <el-tag type="danger" size="small" v-if="venItem.role === 2">电商</el-tag>
                                            <el-tag type="success" size="small" v-if="venItem.role === 3">平台</el-tag>
                                        </div>
                                        <img src="@/assets/service/contact_on.png" alt="" title="转接" @click="vendorTransDebounce(venItem.adminId)">
                                    </div>
                                    <div class="empty_trans" v-show="!vendorList.plist.length > 0">
                                        <img src="@/assets/goods/empty_data.png" alt="">
                                        <p>{{ L['暂无平台在线客服~'] }}</p>
                                    </div>
                                </div>
                            </el-scrollbar>
                        </div>
                        <div class="transfer-column">
                            <el-scrollbar>
                                <div class="transfer_list">
                                    <div class="transfer_item" v-for="(venItem, venIndex) in vendorList.mlist" :key="venIndex">
                                        <img :src="venItem.adminAvatar" alt="">
                                        <div class="text_con">
                                            <p class="service_name">{{ venItem.adminName }}</p>
                                            <p class="service_state color-blue" v-if="venItem.workState === 1">{{ L['在线'] }}</p>
                                            <p class="service_state color-red" v-if="venItem.workState === 2">{{ L['忙碌'] }}</p>
                                            <p class="service_state color-yellow" v-if="venItem.workState === 3">{{ L['休息'] }}</p>
                                        </div>
                                        <div class="type" v-if="venItem.isSupper === 0">
                                            <el-tag type="danger" size="small" v-if="venItem.role === 2">电商</el-tag>
                                            <el-tag type="success" size="small" v-if="venItem.role === 3">平台</el-tag>
                                        </div>
                                        <img src="@/assets/service/contact_on.png" alt="" title="转接" @click="vendorTransDebounce(venItem.adminId)">
                                    </div>
                                    <div class="empty_trans" v-show="!vendorList.mlist.length > 0">
                                        <img src="@/assets/goods/empty_data.png" alt="">
                                        <p>{{ L['暂无电商在线客服~'] }}</p>
                                    </div>
                                </div>
                            </el-scrollbar>
                        </div>
                    </div>
                </el-dialog>
                <!-- 客服转接框end -->
                <!-- 设置备注start -->
                <el-dialog title="设置备注" v-model="dialogVisibleSetting" custom-class="dialog-modal" top="25vh">
                    <div class="setting_box">
                        <el-form class="w-full" ref="settingFormRef" :model="settingFormData" size="small" label-width="auto" label-position="left">
                            <el-form-item label="备注">
                                <el-input v-model="settingFormData.remark" type="textarea" :autosize="{minRows: 8, maxRows: 8}" :maxlength="140" placeholder="请输入备注" @blur="handleChangeRemark" show-word-limit clearable />
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" @click="handleSaveSetting">保存</el-button>
                                <el-button type="default" @click="dialogVisibleSetting = false">关闭</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </el-dialog>
                <!-- 设置备注end -->
                <!-- 聊天记录start -->
                <el-dialog title="聊天记录" v-model="dialogVisibleChatMsg" custom-class="chatmsg-dialog-modal" top="1vh">
                    <div class="chatmsg_box">
                        <chatHistory mode="dialog" :memberId="socketInfo.data.memberId" :show="dialogVisibleChatMsg"></chatHistory>
                    </div>
                </el-dialog>
                <!-- 聊天记录end -->
            </section>
            <section v-show="!curChatMemberId">
                <div class="empty_data">
                    <img src="@/assets/goods/empty_data.png" />
                    <p>{{ L['请选择访客开启对话～'] }}</p>
                </div>
            </section>
            <section v-show="curChatMemberId && curChatMemberId != socketInfo.data.memberId">
                <div class="empty_data">
                    <img src="@/assets/goods/empty_data.png" />
                    <p class="empty-tips" v-show="switchStatus === 1">{{ L['会话连接中'] }}</p>
                    <p class="empty-tips" v-show="switchStatus === 2">{{ L['会话连接成功'] }}</p>
                    <p class="empty-tips" v-show="switchStatus === 3">{{ L['会话连接失败'] }}</p>
                    <p class="empty-btn" @click="handleSwitchMember" v-show="switchStatus === 3">{{ L['重新连接'] }}</p>
                </div>
            </section>
        </div>
        <Contextmenu ref="contextmenu"></Contextmenu>
        <chatRightList @getObj="getObj" @sendVideo="sendVideo" ref="right" :class="{'hide': isCollapseRight}" />
    </div>
</template>
<script>
import "element-plus/lib/index.full.js"
import { ref, reactive, getCurrentInstance, onMounted, onUnmounted, watch, watchEffect } from 'vue'
import chatLeftList from './chatLeftList';
import chatRightList from './chatRightList';
import chatHistory from './../chatHistory';
import { useStore } from "vuex";
import { pcUrl, sourceUrl } from '@/utils/config.js'
import { ElMessage } from 'element-plus'
import { emoji, emojiPath } from '@/utils/data.js';
import Contextmenu from '@/components/Contextmenu';
import 'viewerjs/dist/viewer.css';
import Viewer from 'viewerjs';
import debounce from 'lodash/debounce';
import { ElLoading } from 'element-plus'

export default {
    name: 'chatPage',
    components: {
        chatLeftList,
        chatRightList,
        Contextmenu,
        chatHistory
    },
    emits: ['getCurMemberId'],
    beforeCreate() {
        console.log('super');
         console.log(this.sockets);
        //监听连接成功事件
        this.sockets.subscribe('get_room_info', (data) => {
            console.log('get_room_info: ', data);
            console.log('connectBaseData: ', this.connectBaseData);
            console.log('切换房间 data.adminId=', data.adminId, ' userId=', this.connectBaseData.userId, ' data.memberId=', data.memberId, ' curChatMemberId=', this.curChatMemberId);
            if (data.adminId == this.connectBaseData.userId) {
                console.log('切换房间成功');
                this.switchStatus = 2;
                this.socketInfo.data = data; // 房间会员和客服信息
                // this.getChatLog(() => { // 聊天记录
                //     setTimeout(() => {
                //         this.scrolltoNewMsg();
                //     }, 800);
                // });
                // this.$refs.right.getSwitch(this.socketInfo.data.memberId); // 右侧订单足迹
            } else {
                this.switchStatus = 3;
                console.log('切换房间失败');
            }
        });
        this.sockets.subscribe('get_send_msg', (data) => {
            //如果是会员发送过来的话需要播放声音
            if (data.userType == 1 && (localStorage.msgVoiceSetting === true || localStorage.msgVoiceSetting === 'true')) {
                this.play();
            }
            this.msgList.list.push(data);
        });
        this.sockets.subscribe('get_member_source_url', (data) => {
            console.log('get_member_source_url: ', data);
            this.chatSourceUrl = data.sourceUrl ? decodeURIComponent(data.sourceUrl) : '';
        });
        this.sockets.subscribe('get_read_msg', (data) => {
            document.title = '有鱼生活 - 客服助手'
            let allData = data.msgIds.split(',');
            this.msgList.list.map(item => {
                if (allData.indexOf(item.msgId)) {
                    item.msgState = 1;
                }
            });
        });
        // 撤回消息
        this.sockets.subscribe('get_revoke_msg', (data) => {
            console.log('get_revoke_msg: ', data);
            console.log('this.socketInfo.data', this.socketInfo.data);
            if (this.socketInfo.data.memberId == data.memberId) {
                if (data.result === 1) { // 撤回成功
                    this.msgList.list.some(item => {
                        if (item.msgId === data.msgId) {
                            item.msgState = 3;
                            return true;
                        }
                    });
                } else {
                    ElMessage('撤回失败');
                }
            }
        });
        this.sockets.subscribe('get_member_read_all', () => {
            document.title = '有鱼生活 - 客服助手'
            this.msgList.list.map(item => {
                item.msgState = 1;
            });
        });

    },
    setup(props, { emit }) {
        const connectBaseData = { userId: localStorage.adminId, role: localStorage.role };
        const { proxy } = getCurrentInstance();
        const store = useStore();
        const quickReplyList = reactive({
            list: []
        })
        const L = proxy.$getCurLanguage()

        const isCollapseLeft = ref(false); // 切换左侧客户信息
        const isCollapseRight = ref(false); // 切换右侧商品信息
        const storeInfo = reactive({ data: store.state.storeInfo });
        const msgVoiceSetting = ref(store.state.msgVoiceSetting);
        const textarea = ref('');
        const chatSourceUrl = ref('');//当前会员的页面来源
        const curChatMemberId = ref(0);//当前聊天窗口会员Id
        const curChatMemberInfo = reactive({ data: {} });//当前聊天会员的信息
        const socketInfo = reactive({ data: {} });//socket连接成功返回的房间信息
        const msgList = reactive({ list: [] });//聊天列表
        const dialogVisible = ref(false); // 客服列表弹窗
        const dialogVisibleSetting = ref(false); // 设置联系人备注弹窗
        const dialogVisibleChatMsg = ref(false); // 聊天记录弹窗
        const settingFormData = reactive({remark: ''}) // 设置备注表单
        const popFlag = ref(false)
        const hasMore = ref(true);
        const switchStatus = ref(0); // 切换会员中
        const minMsgId = ref('');//当前消息聊天记录列表里的最小消息id
        const vendorList = reactive({
            lsit: [],
            mlist: [],
            plist: [],
        })
        const scrollTop = ref(false)
        const flag = ref('')
        const clientWidth = ref(0);
        const clientHeight = ref(0)
        const emojis = reactive(emoji)
        const role = ref(localStorage.role); // 2-电商 3-平台 (列表用)
        //发送消息
        const send = () => {
            console.log('进入');
            let contentTest = textarea.value.replace(/&nbsp;|\s+/g, ''); //没有内容的话直接结束
            if (!contentTest.trim() && !contentTest) {
                return false;
            }

            if (contentTest.length > 50000) {
                ElMessage('超过最大字符限制')
                return
            }
            let content = textarea.value; //没有内容的话直接结束

            let msgData = {};
            msgData.memberId = socketInfo.data.memberId;
            msgData.adminId = socketInfo.data.adminId;
            msgData.msgType = '1';
            msgData.msg = {
                content: content
            };
            console.log(msgData);
            proxy.$socket.emit("send_msg", { ...msgData, ...connectBaseData });
            textarea.value = '';//清空输入框的内容
            proxy.$refs.sendBox.innerHTML = ''
            scrollTop.value = false

            scrolltoNewMsg()
        }

        //打开客服转接
        const chatTransfer = () => {
            dialogVisible.value = true
            proxy.$socket.emit('pre_switch_admin', { ...connectBaseData }, (data) => {
                vendorList.plist = data.filter(item => item.role === 3);
                vendorList.mlist = data.filter(item => item.role === 2);
            })
        }

        //打开聊天记录弹窗
        const handleOpenChatMsg = () => {
            dialogVisibleChatMsg.value = true;
        }

        // 点击转接客服
        const vendorTrans = (adminId) => {
            proxy.$socket.emit('switch_admin', { switchAdminId: adminId, memberId: curChatMemberId.value, ...connectBaseData }, (res) => {
                //删除将该会员从最近联系人列表中删除
                proxy.$refs.left.closeChatMember(curChatMemberId.value)
                ElMessage({
                    showClose: true,
                    message: '转接成功',
                    type: 'success'
                });
                dialogVisible.value = false;
            })
        }

        const vendorTransDebounce = debounce(vendorTrans, 250);

        // 置顶会员
        const handleToggleTop = () => {
            proxy.$refs.left.handleToggleTop(curChatMemberId.value)
        }

        // 删除会员 - 将该会员从最近联系人列表中删除
        const handleDeleteMember = () => {
            proxy.$refs.left.closeChatMember(curChatMemberId.value)
        }

        // 切换会员 - 重新连接
        const handleSwitchMember = () => {
            proxy.$socket.emit("admin_change_room", { memberId: curChatMemberId.value, oldMemberId: curChatMemberId.value, ...connectBaseData });
        }

        //获取现在的会员的信息
        const curChatMemInfo = (data) => {
            console.log(data);
            if (data.memberId != undefined && data.memberId) {
                curChatMemberInfo.data = data;
            } else {
                curChatMemberId.value = 0;
            }
        }

        //切换会员需要重新获取聊天列表以及用户的信息
        const switchMember = (data) => {
            let { memberId, memberName, memberRemarks, action } = data;
            console.log('switchMember get=', data);
            curChatMemberInfo.data.memberName = memberName;
            curChatMemberInfo.data.memberRemarks = memberRemarks;
            curChatMemberId.value = memberId;
            if (action === 'update') return;
            emit('getCurMemberId', data);
            scrollTop.value = false
            hasMore.value = true
            flag.value = memberId.toString()
            minMsgId.value = '';
            msgList.list = [];
            switchStatus.value = 1; // 切换状态
            getChatLog(() => {
                setTimeout(() => {
                    console.log('switchMember getChatLog');
                    scrolltoNewMsg();
                }, 500);
            });
            proxy.$refs.right.getSwitch(curChatMemberId.value)

        }

        //获取订单，足迹，推荐商品，并发送
        const getObj = (data, type) => {
            let msgData = {};
            msgData.memberId = socketInfo.data.memberId;
            msgData.adminId = socketInfo.data.adminId;
            if (type == 'foot') {
                msgData.msgType = 3;
                msgData.msg = {
                    productId: data.productId,
                    goodsImage: data.goodsImage,
                    goodsName: data.goodsName,
                    goodsPrice: data.productPrice,
                    goodsId: data.goodsId
                };
            } else if (type == 'recom') {
                msgData.msgType = 3;
                msgData.msg = {
                    productId: data.productId,
                    goodsImage: data.masterImg,
                    goodsName: data.goodsName,
                    goodsPrice: data.goodsPrice,
                    goodsId: data.goodsId
                };
            } else if (type === 'order') {
                msgData.msgType = 4;
                msgData.msg = {
                    orderSn: data.orderSn,
                    createTime: data.addTime,
                    orderStateValue: data.orderStateValue,
                    productId: data.goodsItem.productId,
                    goodsImage: data.goodsItem.productImage,
                    goodsName: data.goodsItem.goodsName,
                    goodsPrice: data.goodsItem.moneyAmount,
                };
            }
            proxy.$socket.emit("send_msg", { ...msgData, ...connectBaseData });
            scrollTop.value = false
            scrolltoNewMsg()
        }

        // 获取订单，足迹，推荐商品，中的并发送
        const sendVideo = (data, type) => {
            console.log('data: ', data);
            let msgData = {}
            msgData.memberId = socketInfo.data.memberId;
            msgData.adminId = socketInfo.data.adminId;
            msgData.msgType = '7';
            msgData.msg = {
                video: data.goodsVideo,
            };
            console.log('msgData: ', msgData);
            proxy.$socket.emit("send_msg", { ...msgData, ...connectBaseData });
            proxy.$nextTick(() => {
                setTimeout(() => {
                    scrollTop.value = false
                    scrolltoNewMsg()
                }, 10)
            })
        }

        //获取快捷回复列表
        const getQuickReplyActive = () => {
            popFlag.value = true
            let params = {
                isShow: '1',
                current: 1,
                type: role.value
            }
            proxy.$get('im-web/v3/helpdesk/admin/quick/list', params).then(res => {
                if (res.state == 200) {
                    quickReplyList.list = res.data.list;
                }

            })
        }

        //获取聊天记录
        const getChatLog = (callback) => {
            let params = {
                memberId: curChatMemberId.value,
            }
            if (minMsgId.value) {
                params.msgId = minMsgId.value;
            }
            proxy.$post('im-web/v3/helpdesk/admin/chat/msgLog', params).then(res => {
                if (res.state == 200) {
                    if (minMsgId.value) {
                        msgList.list = Array.from(res.data).concat(msgList.list);
                    } else {
                        msgList.list = res.data
                    }
                    if (res.data.length > 0) {
                        changeMsgState(res.data);
                    }
                    if (msgList.list.length > 0) {
                        minMsgId.value = msgList.list[0].msgId;
                    }
                    hasMore.value = res.data.length < 10 ? false : true;
                    callback && callback();
                }
            })
        }

        // 查看图片
        const hanldeViewImage = (event) => {
            const viewer = new Viewer(document.querySelector('.chat_window'), {
                filter(image) {
                    return image.className === 'msg-image';
                },
                hidden() {
                    viewer.destroy();
                },
            });
            viewer.show();
        }

        const transEmoji = (src) => {
            const imgSrc = (emojiPath) + "" + (src);
            const imgTag = document.createElement("img");
            imgTag.src = imgSrc;
            proxy.$refs.sendBox.appendChild(imgTag);
            textarea.value = proxy.$refs.sendBox.innerHTML
        }


        const preInput = (e) => {
            var re = /<[^img][^>]+>/g
            textarea.value = e.target.innerHTML.replace(re, "")
        }

        //修改当前消息列表的未读消息为已读
        const changeMsgState = (data) => {
            let tmpMsgIdArray = [];
            data.map(item => {
                if (item.userType == 1 && item.msgState == 2) {
                    tmpMsgIdArray.push(item.msgId);
                }
            });
            if (tmpMsgIdArray.length > 0) {
                proxy.$socket.emit("read_msg", {
                    msgIds: tmpMsgIdArray.join(','),
                    memberId: curChatMemberId.value,
                    ...connectBaseData
                });
            }
        }




        //切换店铺的滑动操作，而且触顶加载则保持滚动条在触顶时的位置，不大跳
        watch(() => flag.value, (nv, ov) => {
            let el = ''
            let client = ''
            if (nv != ov) {
                el = document.getElementById('chat').childNodes[0]
                client = document.getElementById('chat')
                scrollTop.value = false
                new MutationObserver(() => {
                    if (scrollTop.value) {
                        el.scrollTop = client.clientHeight + 400
                        return
                    }
                    scrollToBottom(el);
                }).observe(el, { childList: true, subtree: true });

            }
        })

        watchEffect(() => {
        })


        //滑动至底部方法
        const scrollToBottom = el => {
            el.scrollTop = el.scrollHeight;
            el.scrollTo(0, el.scrollHeight)
        };

        //MutationObserver监听dom变化，当聊天数据最终渲染完成时，将其滑动至底部(优化方法)
        const scroll = () => {
            let el = proxy.$refs.scroll.wrap
            let timeout = ''
            el.addEventListener('scroll', () => {
                if (timeout) clearTimeout(timeout);
                timeout = window.setTimeout(function () {
                    if (el.scrollTop == 0 && hasMore.value) {

                        scrollTop.value = true
                        console.log('scroll getChatLog');
                        getChatLog()
                    }
                }, 500);
            }, true);
            (new MutationObserver(() => {
                if (scrollTop.value) {
                    el.scrollTop = el.clientHeight + 400
                    return
                }
                scrollToBottom(el);
            })).observe(el, { childList: true, subtree: true });
        }

        //发送快捷回复
        const sendQuick = (msg) => {
            let msgData = {};
            msgData.memberId = socketInfo.data.memberId;
            msgData.adminId = socketInfo.data.adminId;
            msgData.msgType = '1';
            msgData.msg = {
                content: msg
            };

            popFlag.value = false
            proxy.$socket.emit("send_msg", { ...msgData, ...connectBaseData });
            scrollTop.value = false
            scrolltoNewMsg()
        }

        //发送消息时并，滚到最底部
        const scrolltoNewMsg = () => {
            var div = proxy.$refs.scroll.wrap
            proxy.$nextTick(() => {
                div.scrollTo(0, div.scrollHeight + 10000)
            })

        }

        // 消息提示音
        const play = () => {
            let audioElement = document.createElement('audio');
            let voice = require('@/assets/voice/msg.mp3')
            audioElement.setAttribute('src', voice);
            audioElement.setAttribute('autoplay', 'autoplay');
        }

        // 发送图片
        const getImage = (e) => {

            let tar_file = e.target.files[0]

            if (tar_file.type.indexOf('image') < 0) {
                ElMessage.error('请选择图片类型文件')
                return
            }

            if (tar_file.size / 1024 / 1024 > 20) {
                ElMessage.error('图片大小不能超过 20MB!')
                return
            }


            if (e.target.files[0]) {
                const loading = ElLoading.service({
                    lock: true,
                    text: '上传中，请稍等...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                proxy.$post('im-web/v3/oss/common/upload', {
                    source: 'goods',
                    file: e.target.files[0]
                }, 'form').then(res => {
                    if (res.state == 200) {
                        proxy.$refs.imageFile.value = ""
                        let msgData = {}
                        msgData.memberId = socketInfo.data.memberId;
                        msgData.adminId = socketInfo.data.adminId;
                        msgData.msgType = '2';
                        msgData.msg = {
                            pic: res.data.url,
                            width: res.data.width,
                            height: res.data.height
                        };
                        proxy.$socket.emit("send_msg", { ...msgData, ...connectBaseData });
                        proxy.$nextTick(() => {
                            setTimeout(() => {
                                scrollTop.value = false
                                scrolltoNewMsg()
                            }, 10)

                        })

                    }
                }).then(res => {
                    loading.close();
                })
            }
        }

        // 发送视频
        const getVideo = (e) => {

            let tar_file = e.target.files[0]

            if (tar_file.type.indexOf('video/mp4') < 0) {
                ElMessage.error('请选择视频类型文件!')
                return
            }

            if (tar_file.size / 1024 / 1024 > 50) {
                ElMessage.error('视频大小不能超过 50MB!')
                return
            }

            if (e.target.files[0]) {
                const loading = ElLoading.service({
                    lock: true,
                    text: '上传中，请稍等...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                proxy.$post('im-web/v3/oss/common/upload', {
                    source: 'video',
                    file: e.target.files[0]
                }, 'form').then(res => {
                    if (res.state == 200) {
                        proxy.$refs.videoFile.value = ""
                        let msgData = {}
                        msgData.memberId = socketInfo.data.memberId;
                        msgData.adminId = socketInfo.data.adminId;
                        msgData.msgType = '7';
                        msgData.msg = {
                            video: res.data.url,
                        };
                        proxy.$socket.emit("send_msg", { ...msgData, ...connectBaseData });
                        proxy.$nextTick(() => {
                            setTimeout(() => {
                                scrollTop.value = false
                                scrolltoNewMsg()
                            }, 10)

                        })

                    }

                }).then(res => {
                    loading.close();
                })
            }
        }

        const toDetail = (item, type) => {
            switch (type) {
                case 'order': {
                    let url
                    url = `${sourceUrl}manage_order/order_lists_to_detail?orderSn=${item.orderSn}`

                    window.open(url, '_blank')
                    break;
                }

                case 'goods': {
                    let url
                    if (pcUrl.trim()) {
                        url = `${pcUrl}goods/detail?productId=${item.defaultProductId || item.productId}`
                    } else {
                        url = `${sourceUrl}manage_goods/list_to_detail?id=${item.goodsId}`
                    }

                    window.open(url, '_blank')
                    break;
                }
            }
        }


        const listenPaste = (e) => {
            if (e.clipboardData && e.clipboardData.items) {
                let items = e.clipboardData.items || [];
                let file = null;
                if (items && items.length) {
                    for (let i = 0; i < items.length; i++) {
                        if (items[i].type.indexOf('image') !== -1) {
                            file = items[i].getAsFile();
                        }
                    }
                }
                // 获取文件之后就可以上传服务器或者其他操作啦
                if (file) {
                    let obj = {
                        target: {
                            files: [file]
                        }
                    }
                    proxy.$confirm('是否要发送剪切板的图片?', '提示', {
                        confirmButtonText: '确认',
                        cancelButtonText: '取消'
                    }).then(() => {
                        getImage(obj)
                    })
                } else {
                    textFormat(e)
                }
            }
        }

        //复制其他地方的文本需要这样的特殊处理,不能简单的innerHTML = xxxxx
        const textFormat = (e) => {
            e.preventDefault()
            var text
            var clp = (e.originalEvent || e).clipboardData
            if (clp === undefined || clp === null) {
                text = window.clipboardData.getData('text') || ''
                if (text !== '') {
                    if (window.getSelection) {
                        var newNode = document.createElement('span')
                        newNode.innerHTML = text
                        window.getSelection().getRangeAt(0).insertNode(newNode)
                    } else {
                        document.selection.createRange().pasteHTML(text)
                    }
                }
            } else {
                text = clp.getData('text/plain') || ''
                if (text !== '') {
                    document.execCommand('insertText', false, text)
                }
            }
        }

        // 是否显示撤回
        const onContextmenu = (event, message) => {
            console.log('onContextmenu event: ', event);
            event.preventDefault();
            const isShow = Date.now() - (new Date(message.createTime)).getTime() < (2 * 60 * 1000);
            if (!isShow) return;
            let y = event.y;
            let x = event.x;
            if (event.type === 'touchend') {
                y = event.changedTouches[0].pageY;
                x = event.changedTouches[0].pageX;
            }
            proxy.$refs.contextmenu.onShow({ 
                y,
                x,
                items: [
                    {
                        label: '撤回',
                        onClick: () => handleRevoke(message)
                    }
                ]
            });
        }

        // 撤回消息
        const handleRevoke = (message) => {
            proxy.$socket.emit('revoke_msg', { msgId: message.msgId, memberId: message.memberId, ...connectBaseData });
        }
        
        // 撤回消息-重新编辑
        const handleReedit = (message) => {
            proxy.$refs.sendBox.innerHTML = JSON.parse(message.msgContent)?.content;
            textarea.value = proxy.$refs.sendBox.innerHTML;
        }

        // 打开联系人设置弹窗
        const handleOpenSetting = () => {
            settingFormData.remark = curChatMemberInfo.data.memberRemarks;
            dialogVisibleSetting.value = true;
        }

        // 保存联系人设置
        const handleSaveSetting = () => {
            let params = {
                memberId: curChatMemberId.value,
                memberRemarks: settingFormData.remark,
            }
            proxy.$post('im-web/v3/member/admin/member/updateMemberRemarks', params).then(res => {
                ElMessage.success(res.msg)
                curChatMemberInfo.data.memberRemarks = settingFormData.remark;
                dialogVisibleSetting.value = false;
                proxy.$refs.left.handleChangeRemark(curChatMemberId.value, settingFormData.remark); // 更新联系人备注
            });
        }

        // 切换左边客户信息显示
        const handleToggleLeft = () => {
            isCollapseLeft.value = !isCollapseLeft.value;
        }

        // 切换右边商品信息显示
        const handleToggleRight = () => {
            if (clientWidth.value <= 1024) {
                isCollapseLeft.value = true;
            }
            isCollapseRight.value = !isCollapseRight.value;
        }

        // 窗口调整
        const windowResize = () => {
            clientWidth.value = document.body.offsetWidth || document.documentElement.offsetWidth;
            clientHeight.value = document.body.clientHeight || document.documentElement.clientHeight;
            if (clientWidth.value <= 1024) {
                isCollapseRight.value = true;
            } else {
                isCollapseRight.value = false;
            }
        }

        onMounted(() => {
            window.addEventListener('resize', windowResize);
            windowResize();
            scroll();
        })

        onUnmounted(() => {
            window.removeEventListener('resize', windowResize);
        })

        return {
            scrolltoNewMsg,
            switchStatus,
            handleToggleTop,
            handleSwitchMember,
            hanldeViewImage,
            onContextmenu,
            handleRevoke,
            handleReedit,
            settingFormData,
            handleSaveSetting,
            handleOpenSetting,
            handleDeleteMember,
            isCollapseLeft,
            isCollapseRight,
            handleToggleLeft,
            handleToggleRight,
            textarea,
            msgList,
            socketInfo,
            send,
            chatTransfer,
            dialogVisible,
            dialogVisibleSetting,
            dialogVisibleChatMsg,
            handleOpenChatMsg,
            getObj,
            getVideo,
            sendVideo,
            getQuickReplyActive,
            quickReplyList,
            sendQuick,
            popFlag,
            getChatLog,
            chatSourceUrl,
            curChatMemInfo,
            curChatMemberInfo,
            switchMember,
            minMsgId,
            changeMsgState,
            curChatMemberId,
            vendorList,
            vendorTrans,
            vendorTransDebounce,
            play,
            msgVoiceSetting,
            getImage,
            connectBaseData,
            storeInfo,
            clientHeight,
            emojis,
            preInput,
            transEmoji,
            emojiPath,
            L,
            toDetail,
            listenPaste
        }
    }
}
</script>
<style lang="scss">
.pop_header {
    height: 38px;
    background: #F3F3F4;
    display: flex;
    justify-content: space-between;

    p {
        margin-left: 10px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 38px;
    }

    i {
        font-size: 16px;
        margin-right: 10px;
    }
}

.emoji_s {
    height: 200px;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;

    .emoji_item {
        padding: 0 5px;
    }
}

.send-textarea {
    box-sizing: border-box;
    width: 100%;
    height: 94px;
    padding: 0px 19px 0px 19px;
    background-color: #fff;
    color: #252525;
    font-size: 13px;
    font-family: inherit;
    word-break: break-all;
    white-space: normal;
    overflow-y: auto;
    outline: none;

    @media screen and (max-width: 640px) {
        height: 54px;
    }

    img {
        width: 16px;
        height: 16px;
    }
}

.dialog-modal {
    width: 600px !important;
    @media screen and (max-width: 640px) {
        width: 365px !important;
    }
}

.chatmsg-dialog-modal {
    width: 1000px !important;
    @media screen and (max-width: 640px) {
        width: 365px !important;
    }
}

.pop_list {
    margin-top: 5px;
    height: 300px;
    overflow: auto;

    .pop_item {
        margin-left: 10px;
        padding: 12px 0px;
        border-bottom: 1px solid #e7e7e7;
        width: 97%;

        &:hover {
            background: #F8F8F8;
        }
    }
}

.goods_info_bottom_detial {
    position: relative;

    .goods_info_bottom_ok {
        position: absolute;
        padding-left: 5px;
        padding-right: 5px;
        height: 20px;
        background: #EEEEEE;
        border-radius: 10px;
        bottom: 0;
        right: 10px;
        text-align: center;
        line-height: 20px;
        color: #666666 !important;
    }
}

#el-textarea {
    border: none;

    textarea {
        outline: none;
        border: none;
    }
}


.sld_chat_page {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    background-color: #fff;
    display: flex;
    flex-shrink: 0;

    .chat_zone {
        flex: 1;
        height: 100%;

        &.shrink {
            @media screen and (max-width: 1024px) {
                margin-left: -250px;
            }
        }

        .chat_zone_container {
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 100%;
        }

        .empty_data {
            padding-top: 220px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            img {
                width: 80px;
            }

            p {
                margin-top: 10px;
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #bbb;
            }

            .empty-btn {
                border: 1px solid green;
                padding: 5px 10px;
                font-size: 14px;
                color: green;
                cursor: pointer;
            }
        }

        .top {
            display: flex;
            justify-content: space-between;
            height: 50px;
            padding: 15px 0px;
            @media screen and (max-width: 640px) {
                height: auto;
                padding: 5px 0px;
            }

            .top_title {
                display: flex;
                flex-direction: row;
                .top_title_container {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    min-width: 140px;
                    height: 20px;
                    line-height: 20px;
                    border-left: 4px solid #0563FF;
                    padding-left: 10px;
                    margin-left: 2px;
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;
                    cursor: pointer;

                    @media screen and (max-width: 640px) {
                        min-width: auto;
                    }

                    .source-url {
                        @media screen and (max-width: 640px) {
                            display: none;
                        }
                    }
                    
                }
                .icon {
                    margin-right: 5px;
                    display: block;
                    width: 20px;
                }
                span {
                    @media screen and (max-width: 640px) {
                        font-size: 12px;
                    }
                }
                .btn-remark {
                    display: flex;
                    align-items: center;
                    margin-left: 5px;
                    
                    .text {
                        display: inline-block;
                        max-width: 600px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        color: #333333;

                        @media screen and (max-width: 1366px) {
                            max-width: 300px;
                        }

                        @media screen and (max-width: 640px) {
                            display: none;
                        }
                    }
                }
            }
            .action_box {
                display: flex;
                .btn-delete {
                    padding: 0 5px;
                    display: none;
                    @media screen and (max-width: 640px) {
                        display: inline-block;
                    }
                }
                .btn-top {
                    padding: 0 5px;
                    display: none;
                    .icon {
                        display: block;
                        width: 20px;
                    }
                    @media screen and (max-width: 640px) {
                        display: inline-block;
                    }
                }
                .toggle-menu {
                    padding: 0 10px;
                    color: #999;
                    transition: all .3s;

                    &.shrink {
                        transform: rotate(-90deg);
                    }
                    .icon {
                        display: block;
                        width: 20px;
                    }
                }
            }
        }

        .chat_wrap {
            position: relative;
            height: calc(100% - 50px);
            width: 100%;
            border-radius: 6px;
            /* border-top-right-radius: 6px; */
            border: 1px solid #EEEEEE;
            @media screen and (max-width: 640px) {
                height: calc(100% - 30px);
            }

            .chat_window {
                min-width: 180px;
                height: calc(100% - 196px);
                background-color: #F5F5F5;
                padding-top: 12px;
                padding-left: 15px;
                padding-right: 13px;

                @media screen and (max-width: 640px) {
                    height: calc(100% - 156px);
                    padding-left: 5px;
                    padding-right: 3px;
                }

                .time_line {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 12px auto;
                    line-height: 22px;
                    text-align: center;
                    font-size: 12px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    // width: 160px;
                    // height: 22px;
                    

                    .time_line_msg {
                        border-radius: 11px;
                        padding: 3px 10px;
                        color: #999999;
                        background: #EEEEEE;
                    }

                    .btn-reedit {
                        margin-left: 5px;
                        color: #0e6fd7;
                    }
                }

                #text_center {
                    width: 589px;
                    height: 104px;
                    background: #FFFFFF;
                    border-radius: 6px;

                    .goods_model {
                        display: flex;
                        justify-content: center;
                        align-content: center;
                        padding-top: 10px;
                        padding-left: 10px;
                    }
                }

                .chat_ladder {
                    display: flex;
                    align-items: flex-end;
                    margin-top: 20px;
                    margin-bottom: 20px;
                    align-items: flex-start;
                    box-sizing: border-box;

                    &>img {
                        width: 38px;
                        height: 38px;
                        border-radius: 50%;

                        &.user_avatar {
                            margin-left: 10px;
                        }
                    }

                    .content_right {
                        margin-left: 10px;

                        p {

                            font-size: 12px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #999999;
                            margin-bottom: 5px;
                        }

                        .divide {
                            padding: 10px;
                        }

                        .content_text {
                            display: inline-block;
                            padding: 11px;
                            position: relative;
                            max-width: 589px;
                            background: #FFFFFF;
                            border-radius: 6px;

                        }

                        .order_type {
                            cursor: pointer;

                            .goods_info {
                                min-width: 300px;
                                @media screen and (max-width: 1366px) {
                                    min-width: 220px;
                                }
                                @media screen and (max-width: 1024px) {
                                    min-width: auto;
                                }

                                .goods_info_title {

                                    font-size: 16px;
                                    font-family: Microsoft YaHei;
                                    font-weight: 400;
                                    color: #333333;
                                }
                            }

                            .order_title {
                                display: flex;
                                height: 20px;
                                justify-content: space-between;
                                align-items: flex-start;
                                border-bottom: 1px solid #F2F2F2;
                                margin-bottom: 8px;

                                .order_sn {
                                    font-size: 12px;
                                    color: #999999;
                                    font-family: Microsoft YaHei;

                                    i {
                                        font-style: normal;
                                        color: #666666;
                                    }
                                }

                                .order_time {
                                    color: #999999;
                                }
                            }
                        }

                        .goods_type {
                            min-width: 300px;
                            cursor: pointer;
                            @media screen and (max-width: 1366px) {
                                min-width: 220px;
                            }
                            @media screen and (max-width: 1024px) {
                                min-width: auto;
                            }
                        }

                        .image_type {

                            min-height: 100px;

                            img {
                                max-width: 200px;
                            }

                            .image-slot {
                                width: 200px;
                                height: 200px;
                            }
                        }

                        .text_type {
                            max-width: 360px;
                            word-break: break-all;
                            align-items: center;
                            // display: flex;

                            white-space: pre-wrap !important;
                            text-overflow: unset !important;
                            flex-wrap: wrap;
                            word-break: break-word;
                            word-wrap: break-word;

                            img {
                                width: 16px;
                                height: 16px;
                            }
                        }
                    }

                }


                .user {
                    justify-content: flex-start;

                    .content_text {
                        background: #FFFFFF;

                        border-radius: 6px;
                    }
                }

                .merchant {
                    padding-right: 20px;
                    justify-content: flex-end;
                    @media screen and (max-width: 640px) {
                        padding-right: 10px;
                    }

                    p {
                        text-align: right;
                    }

                    .content_text {
                        .tiny_triangle {
                            position: absolute;
                            right: -9px;
                            width: 0;
                            height: 0;
                            border-right: 14px solid transparent;
                            border-bottom: 13px solid #fff;
                        }

                        background: #fff;
                        border-radius: 6px;
                    }

                    .msg_read {
                        flex-shrink: 0;
                        align-self: flex-end;
                        font-size: 12px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #0E6FD7;
                    }

                    .msg_not_read {
                        align-self: flex-end;
                        height: 100%;
                        font-size: 12px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #0E6FD7;
                    }
                }
            }

            .chat_input {
                padding-bottom: 10px;

                .chat_tool {
                    position: relative;
                    padding: 6px;

                    .btn-video {
                        width: 30px;
                    }

                    img {
                        margin-right: 10px;
                    }

                    .btn-chatmsg {
                        display: inline-block;
                        width: 30px;

                        @media screen and (max-width: 640px) {
                            display: none;
                        }
                    }

                    .btn-scroll {
                        position: absolute;
                        top: 10px;
                        right: 10px;
                        
                        .icon {
                            width: 22px;
                        }
                    }
                }

                .chat_input_area {
                    padding: 6px;
                    margin-top: 6px;

                    textarea {
                        max-height: 94px;

                    }
                }

                button {
                    width: 80px;
                    height: 30px;
                    background: #0E6FD7;
                    border-radius: 6px;
                    border: none;
                    color: #fff;
                }

                .input_button {
                    display: flex;
                    justify-content: flex-end;
                    padding-right: 23px;

                    button {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }


            }
        }
    }
}

.goods_model {
    display: flex;

    &>img {
        width: 84px;
        height: 84px;
        background: #707070;
        border-radius: 6px;
    }

    .goods_info {
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        max-width: 450px;

        .goods_info_title {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            line-height: 18px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            padding-right: 10px;
        }

        .goods_info_bottom {
            display: flex;
            justify-content: flex-start;

            span:first-child {
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #e2231a;
            }

            span:nth-child(2) {
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #e2231a;
            }
        }
    }
}

.transfer-dialog-modal {
    width: 1000px !important;
    @media screen and (max-width: 640px) {
        width: 365px !important;
    }
}

.transfer-box {
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media screen and (max-width: 640px) {
        flex-direction: column;
        justify-content: flex-start;
    }

    .transfer-column {
        width: 50%;

        @media screen and (max-width: 640px) {
            width: 100%;
        }

        &:first-child {
            border-right: 1px solid #ccc;
            padding-right: 20px;

            @media screen and (max-width: 640px) {
                border-right: none;
                padding-right: 0;
            }
        }
        &:last-child {
            padding-left: 20px;

            @media screen and (max-width: 640px) {
                padding-left: 0;
            }
        }
    }


}

.transfer_list {
    padding: 11px 0px 11px 11px;
    max-height: 720px;

    @media screen and (max-width: 640px) {
        max-height: 250px;
    }

    .transfer_item {
        display: flex;
        border-bottom: 1px solid #F2F2F2;
        padding: 12px 0px;
        position: relative;
        height: 70px;
        width: 98%;

        img {
            width: 40px;
            height: 40px;
        }

        img:nth-of-type(2) {
            position: absolute;
            right: 10px;
            top: 22px;
            width: 23px;
            height: 22px;
        }

        .type {
            position: absolute;
            right: 40%;
            top: 25px;
            color: #00bf0f;
            @media screen and (max-width: 640px) {
                right: 35%;
            }
            &.type-platform {
                color: #ff0000;
            }
        }

        .text_con {
            margin-left: 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .service_name {

                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #333333;

            }

            .service_state {
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                margin-bottom: 3px;
                &.color-blue {
                    color: #0E6FD7;
                }
                &.color-red {
                    color: #ff0000;
                }
                &.color-yellow {
                    color: #ff9421;
                }
            }
        }

    }
}

.el-dialog__header {

    background: #F3F3F4;
}

.el-dialog__title {

    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
}

.empty_quick {
    height: 330px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
        width: 87px;
        height: 55px;
    }

    p {
        margin-top: 11px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
    }
}

.empty_trans {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 90%;

    p {
        margin-top: 11px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
    }
}

#image {
    display: none;
}
#video {
    display: none;
}
</style>
