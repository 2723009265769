<template>
    <div class="sld_chat_right">
        <div class="right_tab">
            <div :class="{ tab1: true, on: 1 == tabIndex }" @click="changeTab(1)">{{ L['用户订单'] }}</div>
            <div :class="{ tab2: true, on: 2 == tabIndex }" @click="changeTab(2)">{{ L['用户足迹'] }}</div>
            <div :class="{ tab3: true, on: 3 == tabIndex }" @click="changeTab(3)">{{ L['店铺商品'] }}</div>
        </div>

        <div class="right_list_con" v-if="1 == tabIndex">
            <el-input placeholder="请输入商品名称/订单号搜索" v-model="current.keyword" @input="getSearchOrder" @clear="getSearchOrder" clearable>
                <template #append>
                    <el-button icon="el-icon-search" @click="getSearchOrder"></el-button>
                </template>
            </el-input>
            <el-scrollbar ref="orderbar">
                <!-- 订单列表start -->
                <div class="orderOwn" v-infinite-scroll="load" v-loading="loadingState.orderState">
                    <div class="order_item"
                        v-for="({ orderSn, addTime, orderStateValue, orderProductList, orderTypeValue, orderType }, orderIndex) in orderList.list"
                        :key="orderIndex">
                        <div class="order_title">
                            <div class="order_title_info">
                                <p><span :class="{'order_type': orderType !== 1}">{{ orderTypeValue }}</span>：{{ orderSn }}</p>
                                <p>{{ L['下单时间'] }}：{{ addTime }}</p>
                            </div>
                            <div class="order_state">{{ orderStateValue }}</div>
                        </div>
                        <div class="goods_model order_type" v-for="(goodsItem, goodsIndex) in orderProductList"
                            :key="goodsIndex" @click="toDetail({ orderSn }, 'order')">
                            <div class="goods_m_img">
                                <img :src="goodsItem.productImage" alt="">
                            </div>
                            <div class="goods_info">
                                <div class="goods_info_title">
                                    {{ goodsItem.goodsName }}
                                </div>
                                <div class="goods_info_bottom">
                                    <span>￥{{ goodsItem.moneyAmount }}</span>
                                    <span class="btn" @click.stop="sendVideo(goodsItem, 'order')" v-if="goodsItem.goodsVideo">{{L['发送视频'] }}></span>
                                    <span class="btn" @click.stop="sendLink({ orderSn, addTime, orderStateValue, goodsItem }, 'order')">{{L['发送链接'] }}></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <loadingState v-if="loadState.orderState == 'first_loading' || orderList.list.length > 0"
                        :state='loadState.orderState' />
                    <div class="empty_data" v-if="!orderList.list.length">
                        <img src="@/assets/goods/empty_data.png" alt="">
                        <p>{{ L['暂无订单～'] }}</p>
                    </div>
                </div>
                <!-- 订单列表start -->
            </el-scrollbar>
        </div>

        <div class="right_list_con" v-if="2 == tabIndex">
            <el-scrollbar ref="footbar">
                <!-- 我的足迹start -->
                <div class="foot_print" v-infinite-scroll="load" v-loading="loadingState.footState">
                    <div class="goods_model" v-for="(footItem, footIndex) in footPrintList.list" :key="footIndex">
                        <div class="goods_m_img">
                            <img :src="footItem.goodsImage" alt="">
                        </div>
                        <div class="goods_info">
                            <div class="goods_info_title">
                                {{ footItem.goodsName }}
                            </div>
                            <div class="goods_info_bottom">
                                <span>￥{{ footItem.productPrice }}</span>
                                <span class="btn" @click.stop="sendVideo(footItem, 'foot')" v-if="footItem.goodsVideo">{{ L['发送视频'] }} ></span>
                                <span class="btn" @click.stop="sendLink(footItem, 'foot')">{{ L['发送链接'] }} ></span>
                            </div>
                        </div>
                    </div>
                    <loadingState v-if="loadState.footState == 'first_loading' || footPrintList.list.length > 0"
                        :state="loadState.footState" />
                    <div class="empty_data" v-if="!footPrintList.list.length">
                        <img src="@/assets/goods/empty_data.png" alt="">
                        <p>{{ L['暂无足迹～'] }}</p>
                    </div>
                </div>
                <!-- 我的足迹end -->
            </el-scrollbar>
        </div>


        <div class="right_list_con goods-search" v-if="3 == tabIndex">
            <el-input placeholder="请输入商品名称搜索" v-model="current.goodsName" @input="getSearchGoods" @clear="getSearchGoods" clearable>
                <template #append>
                    <el-button icon="el-icon-search" @click="getSearchGoods"></el-button>
                </template>
            </el-input>
            <el-scrollbar ref="recombar">
                <!-- 店铺推荐start -->
                <div class="store_recom" v-infinite-scroll="load">
                    <div class="goods_model" v-for="(recomItem, recomIndex) in recomList.list" :key="recomIndex">
                        <div class="goods_m_img">
                            <img :src="recomItem.masterImg" alt="">
                        </div>
                        <div class="goods_info">
                            <div class="goods_info_title">
                                {{ recomItem.goodsName }}
                            </div>
                            <div class="goods_info_bottom">
                                <span>￥{{ recomItem.goodsPrice }}</span>
                                <span class="btn" @click.stop="sendVideo(recomItem, 'recom')" v-if="recomItem.goodsVideo">{{ L['发送视频'] }} ></span>
                                <span class="btn" @click.stop="sendLink(recomItem, 'recom')">{{ L['发送链接'] }} ></span>
                            </div>
                        </div>
                    </div>
                    <loadingState v-if="loadState.recomState == 'first_loading' || recomList.list.length > 0"
                        :state="loadState.recomState" />
                    <div class="empty_data" v-if="!recomList.list.length">
                        <img src="@/assets/goods/empty_data.png" alt="">
                        <p>{{ L['暂无商品～'] }}</p>
                    </div>
                </div>
                <!-- 店铺推荐end -->
            </el-scrollbar>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, reactive, getCurrentInstance, watch } from 'vue'
import loadingState from '@/components/loadingState'
import { pcUrl, sourceUrl } from '@/utils/config.js'
export default {
    name: 'chatRightList',
    components: {
        loadingState
    },
    setup(props, { emit }) {
        const tabIndex = ref(1)
        const changeTab = (index) => {
            tabIndex.value = index
        }
        const { proxy } = getCurrentInstance()
        const orderList = reactive({ list: [] })
        const footPrintList = reactive({ list: [] })
        const recomList = reactive({ list: [] })
        const current = reactive({
            order: 1,
            foot: 1,
            recom: 1,
            goodsName: '',
            keyword: '',
        })
        const hasMore = reactive({
            orderMore: true,
            footMore: true,
            recomMore: true
        })
        const loadState = reactive({
            orderState: '',
            footState: '',
            recomState: ''
        })
        const loadingState = reactive({
            orderState: false,
            footState: false,
            recomState: false
        })
        const clientHeight = ref(0)
        const L = proxy.$getCurLanguage()

        // 对话平台 - 右侧 - 用户订单 - 搜索
        const getSearchOrder = () => {
            current.order = 1;
            getOrderList();
        }

        const getOrderList = () => {
            loadingState.orderState = true;
            const memberId = CurmemberId.value;
            proxy.$get('im-web/v3/business/admin/orderInfo/userOrders', { current: current.order, memberId: CurmemberId.value, keyword: current.keyword }).then(res => {
                if (res.state == 200) {
                    // 如果请求前和请求完成后的会员ID不同，拦截不做处理
                    if (memberId != CurmemberId.value) {
                        console.log('会员ID不同，拦截不做处理');
                        return;
                    }
                    if (current.order == 1) {
                        orderList.list = res.data.list
                    } else {
                        orderList.list = orderList.list.concat(res.data.list)
                    }
                    hasMore.orderMore = checkPaginationHasMore(res.data.pagination)
                    if (hasMore.orderMore) {
                        current.order++
                        loadState.orderState = 'allow_loading_more';
                    } else {
                        loadState.orderState = 'no_more_data';
                    }
                }
            }).finally(() => {
                loadingState.orderState = false;
            })
        }

        const getFootList = () => {
            loadingState.footState = true;
            proxy.$get('im-web/v3/member/admin/productLook/userFootprint', { current: current.foot, memberId: CurmemberId.value }).then(res => {
                if (res.state == 200) {
                    if (current.foot == 1) {
                        footPrintList.list = res.data.list
                    } else {
                        footPrintList.list = footPrintList.list.concat(res.data.list)
                    }
                    hasMore.footMore = checkPaginationHasMore(res.data.pagination)
                    if (hasMore.footMore) {
                        current.foot++
                        loadState.footState = 'allow_loading_more';
                    } else {
                        loadState.footState = 'no_more_data';
                    }
                }
            }).finally(() => {
                loadingState.footState = false;
            })
        }

        // 对话平台 - 右侧 - 店铺商品 - 搜索
        const getSearchGoods = () => {
            current.recom = 1;
            getRecomList();
        }

        const getRecomList = () => {
            // 对话平台 - 右侧 - 店铺商品 - 列表
            proxy.$get('im-web/v3/goods/admin/goods/platformGoods', { current: current.recom, goodsName: current.goodsName }).then(res => {
                if (res.state == 200) {
                    if (current.recom == 1) {
                        recomList.list = res.data.list
                    } else {
                        recomList.list = recomList.list.concat(res.data.list)
                    }
                    hasMore.recomMore = checkPaginationHasMore(res.data.pagination)
                    if (hasMore.recomMore) {
                        current.recom++
                        loadState.recomState = 'allow_loading_more';
                    } else {
                        loadState.recomState = 'no_more_data';
                    }

                }
            })
        }

        const CurmemberId = ref(0)
        const getSwitch = (memberId) => {
            CurmemberId.value = memberId
            current.order = 1
            current.foot = 1
            if (memberId === 0) {
                orderList.list = [];
                footPrintList.list = [];
            } else {
                orderList.list = [];
                footPrintList.list = [];
                getFootList()
                getOrderList()
            }
        }

        const sendLink = (obj, type) => {
            console.log(obj, 'obj')
            emit('getObj', obj, type)
        }

        const sendVideo = (obj, type) => {
            console.log('sendVideo=', obj)
            emit('sendVideo', obj, type)
        }

        const load = () => {
            if (tabIndex.value == 1 && hasMore.orderMore) {
                getOrderList()
            } else if (tabIndex.value == 2 && hasMore.footMore) {
                getFootList()
            } else if (tabIndex.value == 3 && hasMore.recomMore) {
                getRecomList()
            }

        }

        watch(() => CurmemberId.value, (nv, ov) => {
            if (nv != ov) {
                if (proxy.$refs.orderbar) {
                    proxy.$refs.orderbar.wrap.scrollTop = 0
                }

                if (proxy.$refs.footbar) {
                    proxy.$refs.footbar.wrap.scrollTop = 0
                }
            }
        })

        const toDetail = (item, type) => {
            switch (type) {
                case 'order': {
                    let url
                    url = `${sourceUrl}manage_order/order_lists_to_detail?orderSn=${item.orderSn}`

                    window.open(url, '_blank')
                    break;
                }

                case 'goods': {
                    let url
                    if (pcUrl.trim()) {
                        url = `${pcUrl}goods/detail?productId=${item.defaultProductId || item.productId}`
                    } else {
                        url = `${sourceUrl}manage_goods/list_to_detail?id=${item.goodsId}`
                    }

                    window.open(url, '_blank')
                    break;
                }
            }
        }

        const checkPaginationHasMore = ({ current, pageSize, total }) => {
            return current * pageSize < total * 1;
        }

        onMounted(() => {
            clientHeight.value = document.body.clientHeight || document.documentElement.clientHeight
            getRecomList()
        })

        return {
            changeTab,
            tabIndex,
            orderList,
            footPrintList,
            recomList,
            getOrderList,
            getFootList,
            getRecomList,
            getSearchGoods,
            getSearchOrder,
            sendLink,
            sendVideo,
            load,
            loadState,
            loadingState,
            getSwitch,
            CurmemberId,
            clientHeight,
            L,
            toDetail,
            current
        }

    }
}
</script>

<style lang="scss" scoped>
$tab-height: 52px;

.sld_chat_right {
    width: 380px;
    margin-left: 14px;
    flex-shrink: 0;
    transition: all .1s;
    overflow: hidden;

    @media screen and (max-width: 640px) {
        width: 325px;
    }

    &.hide {
        width: 0;
        margin-left: 0;
    }

    .right_tab {
        display: flex;
        flex: 1;
        background-color: #F8F8F8;

        div[class^="tab"] {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            padding: 17px 35px;
            cursor: pointer;

            @media screen and (max-width: 640px) {
                padding: 17px 25px;
            }
        }

        .on {
            border-top: 2px solid #0E6FD7;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: bold !important;
            color: #0E6FD7 !important;
        }
    }

    .right_list_con {
        height: calc(100% - 52px);

        &.goods-search {
            height: calc(100% - 52px - 40px);
        }
        .orderOwn {
            padding-bottom: 30px;
            .order_item {
                padding: 20px;
                border-bottom: 1px solid #f8f8f8;

                .order_title {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding-bottom: 10px;
                    border-bottom: 1px solid #f8f8f8;

                    .order_title_info {
                        p:first-child {
                            font-size: 12px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #666666;
                            margin-bottom: 5px;

                            .order_type {
                                color: green;
                            }
                        }

                        p:nth-child(2) {
                            font-size: 12px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #b3b3b3;
                        }
                    }

                    .order_state {
                        /* width: 50px; */
                        padding-left: 5px;
                        padding-right: 5px;
                        height: 20px;
                        background: #EEEEEE;
                        border-radius: 10px;
                        bottom: 0;
                        right: 10px;
                        text-align: center;
                        line-height: 20px;
                        color: #666666 !important;
                    }
                }

                .order_type {
                    margin-top: 17px;
                }
            }


        }

        .foot_print {
            padding: 0px 20px;
        }

        .store_recom {
            padding: 0px 20px;
        }
    }


    .goods_model {
        display: flex;
        margin-top: 22px;


        .goods_m_img {
            width: 69px;
            height: 66px;
            border-radius: 6px;
            background: #f5f5f5;
            display: flex;
            justify-content: center;
            overflow: hidden;

            img {
                /* width: 69px; */
                height: 66px;
                /* border-radius: 6px; */
            }
        }



        .goods_info {
            margin-left: 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 81%;

            .goods_info_title {
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #333333;
                line-height: 18px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }

            .goods_info_bottom {
                display: flex;
                justify-content: space-between;
                align-items: center;

                span:first-child {
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #e2231a;
                }

                span.btn {
                    font-size: 12px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #e2231a;
                    cursor: pointer;
                }
            }
        }
    }
}

.empty_data {
    height: 300px;
    padding-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 80px;
    }

    p {
        margin-left: 10px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #D5D5D5;
    }
}
</style>
